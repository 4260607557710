<template>
  <div class="audit-logs-table" data-test-audit-logs-table>
    <CardWrapper
      :title="$t('AuditLogsPage.title')"
      :subtitle="
        $t('AuditLogsPage.subtitle', {
          n: tableConfig.xcount,
        })
      "
      :helper-props="{
        short: true,
        hasLink: true,
        hasVideo: true,
        videoType: 'AuditLogsPage',
      }"
    ></CardWrapper>

    <DataTable
      :title="$t('AuditLogsPage.title')"
      :headers="headers"
      :items="_tableItens"
      :class="{ 'no-data': !tableConfig.xcount && !tableApiController.loading }"
      enable-pagination
      enable-pagination-limit-change
      hide-header
      hide-option
      hide-search
      :content-style="false"
      :total-items="tableConfig.xcount"
      :items-per-page="tableConfig.limitPagination"
      :loading="tableApiController.loading"
      :mobile-breakpoint-switch="0"
      @updateList="handleDataTablePagination"
      v-on="$listeners"
    ></DataTable>
  </div>
</template>

<script>
import { alertErrorBus } from '@/helpers/alerts'
import { formatAsString } from '@/helpers/strings/date'

import { getAuditLogs } from '@/services/audit-logs'

import {
  handleAxiosSignalAbortController,
  handleTablePaginationApiRequestWithController,
} from '@/helpers/api'

import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AuditLogsTable',

  data() {
    const tableConfig = {
      limitPagination: 8,
      xcount: 0,
    }

    return {
      callDelayedGetTableData: _.debounce(() => {
        this.tableApiController.loading = true
        this.getTableData()
      }, 500),

      axiosControllers: [],

      tableConfig: tableConfig,
      tableApiPayload: {
        offset: 0,
        limit: tableConfig.limitPagination,
      },
      tableApiController: {
        loading: true,
        itens: Array.from({ length: tableConfig.limitPagination }),
      },

      headers: [
        {
          text: this.$t('AuditLogsPage.table.headers.combo-occupation'),
          value: 'combo-occupation',
          type: 'combo-occupation',
        },
        {
          text: this.$t('AuditLogsPage.table.headers.text-date'),
          value: 'text-date',
          type: 'text',
        },
        {
          text: this.$t('AuditLogsPage.table.headers.text-entity'),
          value: 'text-entity',
          type: 'text',
        },
        {
          text: this.$t('AuditLogsPage.table.headers.text-action'),
          value: 'text-action',
          type: 'text',
          width: '33%',
          style: {
            'max-width': 'unset',
          },
        },
        {
          text: this.$t('AuditLogsPage.table.headers.action-buttons'),
          value: 'action-buttons',
          type: 'action-buttons',
          width: '5rem',
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      _filters: 'AuditLogsPage/filters',
      _entities: 'AuditLogsPage/entitiesI18n',
      _actions: 'AuditLogsPage/actionsI18n',
    }),

    _tableItens() {
      return this.handleTableDataToItens(this.tableApiController.itens)
    },
  },

  watch: {
    _filters: {
      handler(value) {
        this.updatePayload(value)
      },
      immediate: true,
      deep: true,
    },

    tableApiPayload: {
      handler(newValue, oldValue) {
        if (_.isEqual(newValue, oldValue)) return

        this.callDelayedGetTableData()
      },
      deep: true,
    },
  },

  mounted() {
    this.callDelayedGetTableData()
  },

  methods: {
    ...mapActions({
      getAuditLogsEntities: 'AuditLogsPage/getAuditLogsEntities',
      getAuditLogsActions: 'AuditLogsPage/getAuditLogsActions',
    }),
    handleTableDataToItens(data) {
      const minimunDataTableItem = {
        id: 'loading',
        'combo-occupation': {},
        'text-date': '',
        'text-entity': '',
        'text-action': '',
        'action-buttons': [],
      }

      let itens = Array.from(
        { length: this.tableConfig.xcount },
        () => minimunDataTableItem
      )

      if (!Array.isArray(data)) {
        return itens
      }

      data.map((item, index) => {
        if (typeof item !== 'object') {
          itens.splice(index, 1, minimunDataTableItem)
          return
        }

        itens.splice(index, 1, {
          id: `${item.id}`,
          'combo-occupation': {
            name: item.actor.name,
            image: item.actor.photo,
          },
          'text-date': formatAsString(
            this.$t('AuditLogsPage.table.column.text-date'),
            item.createdAt,
            "yyyy-MM-dd'T'HH:mm:ss.SSSX",
            this.$t('locale')
          ),
          'text-entity': this.calcEntityText(item),
          'text-action': this.calcActionText(item),
          'action-buttons': ['view'],
          _AuditLog: item,
        })
      })

      return itens
    },

    calcEntityText(AuditLog) {
      return this._entities?.[AuditLog.entity] || AuditLog.entity
    },

    calcActionText(AuditLog) {
      const action = this._actions?.[AuditLog.action] || AuditLog.action

      if (AuditLog.entity === 'groups' && AuditLog.object) {
        return `${action} ${AuditLog.object.name}`
      }

      const isEditSelf = [
        'users.edit.self',
        'users.invite',
        'users.export',
      ].includes(AuditLog.action)
      if (AuditLog.entity === 'users' && AuditLog.object && !isEditSelf) {
        return `${action} ${AuditLog.object.profile.firstname} ${AuditLog.object.profile.lastname}`
      }

      return action
    },

    async fetchTableData(apiEndpoint, payload) {
      const { signal, axiosControllers } = handleAxiosSignalAbortController(
        this.axiosControllers,
        'tableApiController'
      )
      this.axiosControllers = axiosControllers

      return await handleTablePaginationApiRequestWithController(
        apiEndpoint,
        payload,
        this.tableApiController,
        this.tableConfig,
        signal
      ).catch(error => {
        alertErrorBus(this.$t('alerts.ManagementAuditLogs.error.get'), error)
      })
    },
    async getTableData() {
      const payload = structuredClone(this.tableApiPayload)

      return await Promise.allSettled([
        this.fetchTableData(getAuditLogs, payload),
        this.getAuditLogsEntities(),
        this.getAuditLogsActions(),
      ])
    },

    updatePayload(payloadObject) {
      if (typeof payloadObject !== 'object') {
        return
      }

      this.tableApiPayload = {
        ...this.tableApiPayload,
        ...payloadObject,
      }
    },
    handleDataTablePagination(payload) {
      this.tableApiController.loading = true

      this.updatePayload({
        offset: payload.offset,
        limit: payload.limit,
      })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
