<template>
  <div v-if="_showListDefaultFields" data-test-section-default-fields>
    <DetailSectionAccordion
      type="people"
      :title="$t('PerformanceTab.listDefaultFields.title')"
      :opened="true"
    >
      <DataTable
        data-test-list-default-fields
        :title="$t('PerformanceTab.listDefaultFields.title')"
        :attach="true"
        :loading="loading"
        :hideHeader="true"
        :hideOption="true"
        :rolesReadonly="false"
        :localAddSearch="true"
        :items="filteredItems"
        :headers="_headers"
        :itemsPerPage="4"
        :skeleton-lines="4"
        :limitPagination="4"
        @search="searchDefaultFields($event)"
        @edit="handleEdit($event)"
      />
    </DetailSectionAccordion>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable/DataTable.vue'

import { fetchEntityOkrSettings } from '@/services/papers/index.js'

import { management_performance_default_fields } from '@/helpers/ability'

export default {
  name: 'ListDefaultFields',
  props: {
    settingsID: {
      type: String,
      required: true,
    },
  },

  components: {
    DataTable,
  },

  data() {
    return {
      loading: false,
      filteredItems: [],
      items: [],
      defaultListItens: ['people', 'owners', 'description'],
    }
  },

  computed: {
    _showListDefaultFields() {
      return this.$can('access', management_performance_default_fields)
    },
    _headers() {
      return [
        {
          text: this.$t('PerformanceTab.listDefaultFields.headers.name'),
          value: 'name',
          type: 'text-status',
        },
        {
          text: this.$t('PerformanceTab.listDefaultFields.headers.section'),
          value: 'section',
          type: 'text-status',
        },
        {
          text: this.$t('PerformanceTab.listDefaultFields.headers.type'),
          value: 'type',
          type: 'text-status',
        },
        {
          text: this.$t('PerformanceTab.listDefaultFields.headers.required'),
          value: 'required',
          type: 'text-status',
        },
        {
          text: this.$t('PerformanceTab.listDefaultFields.headers.actions'),
          value: 'actions',
          type: 'action-buttons',
          width: '100',
        },
      ]
    },
  },

  methods: {
    searchDefaultFields(event) {
      const array = []
      if (event.name != '') {
        this.items.map(el => {
          if (el?.name?.label.toLowerCase().includes(event.name.toLowerCase()))
            array.push(el)
        })

        this.filteredItems = array
        return
      }

      if (event.name === '') this.fetchDefaultFields()
    },
    async fetchDefaultFields() {
      this.loading = true

      this.items = []
      const { data: res } = await fetchEntityOkrSettings(this.settingsID)
      const defaultFieldList = res

      this.menuTable = ['edit']

      for (const fieldName of this.defaultListItens) {
        this.items.push({
          actions: this.menuTable,
          id: defaultFieldList.id,
          name: {
            label: this.handleName(fieldName),
            status: '',
          },
          section: {
            label: this.handleSection(fieldName),
            status: '',
          },
          type: {
            label: this.handleType(fieldName),
            status: '',
          },
          required: {
            label: this.handleRequired(
              fieldName,
              defaultFieldList.requiredFields
            ),
            status: '',
          },
          entity: defaultFieldList,
        })
      }
      this.filteredItems = this.items
      this.loading = false
    },
    handleName(defaultFieldName) {
      if (
        defaultFieldName === 'description' &&
        this.$route.params.entityType === 'initiative'
      ) {
        return this.$t(
          `PerformanceTab.listDefaultFields.items.iniciativeDescription`
        )
      }

      return this.$t(
        `PerformanceTab.listDefaultFields.items.${defaultFieldName}`
      )
    },
    handleSection(defaultFieldName) {
      let keyTraduction = null
      switch (defaultFieldName) {
        case 'people':
          keyTraduction = 'people'
          break
        case 'corresponsibles':
          keyTraduction = 'corresponsibles'
          break
        case 'owners':
          keyTraduction = 'detail'
          break
        case 'description':
          keyTraduction = 'detail'
          break

        default:
          keyTraduction = '--'
          break
      }

      return this.$t(
        `PerformanceTab.listDefaultFields.sections.${keyTraduction}`
      )
    },
    handleType(defaultFieldName) {
      let keyTraduction = null
      switch (defaultFieldName) {
        case 'people':
          keyTraduction = 'table'
          break
        case 'corresponsibles':
          keyTraduction = 'table'
          break
        case 'owners':
          keyTraduction = 'field'
          break
        case 'description':
          keyTraduction = 'field'
          break

        default:
          keyTraduction = '--'
          break
      }

      return this.$t(`PerformanceTab.listDefaultFields.type.${keyTraduction}`)
    },
    handleRequired(defaultFieldName, requiredFieldsList) {
      for (let obj of requiredFieldsList) {
        if (obj && obj.includes(defaultFieldName)) {
          return this.$t(`PerformanceTab.listDefaultFields.requiredField.yes`)
        }
      }
      return this.$t(`PerformanceTab.listDefaultFields.requiredField.no`)
    },

    handleEdit(defaultFieldPayload) {
      this.$emit('editDefaultField', defaultFieldPayload)
    },

    updateDefaultListItens() {
      if (this.$route.params.entityType === 'initiative') {
        this.defaultListItens.push('corresponsibles')
      }
    },
  },

  async mounted() {
    this.updateDefaultListItens()
    await this.fetchDefaultFields()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
