<template>
  <div data-test-entities-configurtions>
    <DetailSectionAccordion
      type="settings"
      :title="$t('PerformanceTab.EntitiesConfigurations.title')"
      :opened="true"
    >
      <template v-for="configuration in _settings">
        <v-checkbox
          class="custom-checkbox"
          v-if="configuration.type === 'v-checkbox'"
          :key="configuration.id"
          :data-test-configuration="configuration.id"
          :id="configuration.id"
          :label="
            $t(
              `PerformanceTab.EntitiesConfigurations.settings.${configuration.id}`
            )
          "
          :value="_formConfigurationId"
          color="#dd0041"
          @change="handleSettings(configuration, $event)"
        ></v-checkbox>
      </template>

      <div class="cycle-selection-content" data-test-cycle-selection>
        <v-skeleton-loader
          v-if="loading"
          type="button"
          class="skeleton-loading"
        />

        <ItemSelector
          v-else
          ref="cycleInput"
          :inputConfig="{
            label: $t(
              'PerformanceTab.EntitiesConfigurations.settings.definitionCycle'
            ),
            showAvatar: false,
            closeOnSelect: true,
          }"
          :menuConfig="{
            attach: true,
            showTabs: false,
          }"
          :currentValue="currentCycle"
          :menuOptions="_cycleOptions"
          :infinityScroll="true"
          :persistent="true"
          :watchCurrent="true"
          @update:item="handleCycleChange($event)"
          @infinity:scroll="handleCycleScroll($event)"
          @search:item="searchCycle($event)"
          @focus:input="searchCycles = []"
        />

        <v-tooltip min-width="800" max-width="800" bottom open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              data-test-definition-cycle
              v-bind="attrs"
              v-on="on"
              size="0.75rem"
              class="tooltip-icon"
            >
              fi-rr-info
            </v-icon>
          </template>

          <div
            data-test-definition-cycle-tooltip
            v-html="
              $t(
                'PerformanceTab.EntitiesConfigurations.settings.definitionCycleTooltip'
              )
            "
          ></div>
        </v-tooltip>
      </div>
    </DetailSectionAccordion>
  </div>
</template>

<script>
import {
  fetchEntityOkrSettings,
  updateEntityOkrSettings,
} from '@/services/papers'

import { getCycles } from '@/services/cycles'

import { debounce } from 'lodash'

export default {
  name: 'EntitiesConfigurations',

  props: {
    settingsID: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      formConfigurationId: true,
      handleSubmit: debounce(async function () {
        await this.submit()
      }, 500),

      configurations: {
        'key-result': {
          default: [
            {
              id: 'disableMonthlyDataSeriesAutoGeneration',
              type: 'v-checkbox',
            },
          ],
        },
      },

      form: {},
      settingsForm: {},

      entityType: null,
      entityClass: null,

      currentCycle: {
        data: [
          {
            id: null,
            label: this.$t('PermissionsPage.cycle.suggestEntityCycle'),
          },
        ],
        origin: 'cycle',
      },
      cycles: [],
      searchCycles: [],
      cyclePagination: {
        limit: 20,
        offset: 0,
        total: 0,
      },
    }
  },

  computed: {
    _formConfigurationId() {
      return !this.formConfigurationId
    },
    _settings() {
      return this.getSettings(this.entityType, this.entityClass)
    },
    _cycleOptions() {
      return [
        {
          value: 'cycle',
          label: this.$t('PermissionsPage.cycle.title'),
          type: 'listview',
          items: this.searchCycles.length ? this.searchCycles : this.cycles,
        },
      ]
    },
  },

  methods: {
    setEntity(
      entityType = this.$route.params.entityType,
      entityClass = this.$route.params.entityClass
    ) {
      this.entityType = entityType
      this.entityClass = entityClass
    },

    getSettings(entityType, entityClass) {
      const configurations = this.configurations[entityType]
      if (!configurations) return []

      const currentTypeDefault = configurations?.default || []
      const currentClass = (entityClass && configurations[entityClass]) || []

      return [...currentTypeDefault, ...currentClass]
    },

    calcValueType(type, value = null) {
      if (type === 'v-checkbox') {
        return !!value
      }
    },

    resetForm() {
      const form = {}
      const settings = this._settings
      for (const setting of settings) {
        form[setting.id] = this.calcValueType(
          setting.type,
          this.settingsForm[setting.id]
        )
      }

      this.$set(this, 'form', form)
    },

    handleSettings(configuration, event) {
      const disableMonthlyDataSeriesAutoGeneration = !!event

      const value = this.calcValueType(
        configuration.type,
        !disableMonthlyDataSeriesAutoGeneration
      )
      this.$set(this.form, configuration.id, value)

      this.handleSubmit()
    },

    calcPayload() {
      const payload = structuredClone(this.settingsForm)
      delete payload.id

      const newPayload = {
        ...payload,
        ...(!!payload?.suggestedCycle?.id && {
          suggestedCycle: { id: payload.suggestedCycle.id },
        }),
      }

      if (!payload?.suggestedCycle?.id) newPayload.suggestedCycle = null

      return Object.assign(newPayload, this.form)
    },
    handleCycleChange(cycle) {
      this.$set(this.settingsForm, 'suggestedCycle', { id: cycle.id })
      this.handleSubmit()
    },
    searchCycle(event) {
      const payload = {
        ...(event && { title: event }),
        limit: this.cyclePagination.limit,
        offset: 0,
      }

      this.getCycles(payload, false, true)
    },

    async getCycles(params = {}, infinity = false, search = false) {
      const payload = {
        ...params,
      }

      await getCycles(payload).then(({ data, headers }) => {
        this.cyclePagination.total = parseInt(headers['x-count']) || 0

        const cycles = data
          .filter(el => el.status !== 'inactive')
          .map(el => ({
            id: el.id,
            label: el.title,
          }))

        const result = infinity
          ? this.cycles.concat(cycles)
          : [
              ...(!search
                ? [
                    {
                      id: null,
                      label: this.$t(
                        'PermissionsPage.cycle.suggestEntityCycle'
                      ),
                    },
                  ]
                : []),
              ...cycles,
            ]

        if (search) this.searchCycles = result
        else this.cycles = result
      })

      this.loading = false
    },

    async handleCycleScroll() {
      if (this.cycles.length >= this.cyclePagination.total) return

      this.cyclePagination.offset =
        this.cyclePagination.offset + this.cyclePagination.limit

      await this.getCycles(
        {
          limit: this.cyclePagination.limit,
          offset: this.cyclePagination.offset,
        },
        true
      )

      this.$refs.cycleInput.holdSpamInfinityScroll = false
    },

    async submit() {
      const payload = this.calcPayload()

      await updateEntityOkrSettings(this.settingsForm.id, payload)
        .then(() => {
          this.handleAlert({
            statusAlert: 'success',
            title: this.$t(
              'PerformanceTab.EntitiesConfigurations.alert.put.success'
            ),
          })
        })
        .catch(error => {
          this.handleAlert({
            statusAlert: 'error',
            title: this.$t(
              'PerformanceTab.EntitiesConfigurations.alert.put.error'
            ),
            messageAlert: error?.response?.data?.message,
          })
        })
    },

    handleAlert(payload) {
      this.$emit('display-alert', payload)
    },

    applyCurrentCycle(cycle) {
      this.currentCycle = {
        data: [
          {
            id: cycle.id,
            label: cycle.title,
          },
        ],
        origin: 'cycle',
      }
    },

    async fetchDefaultSettings() {
      await fetchEntityOkrSettings(this.settingsID)
        .then(({ data }) => {
          this.settingsForm = data
          this.formConfigurationId = data.disableMonthlyDataSeriesAutoGeneration
          const currentCycle = data.suggestedCycle

          if (currentCycle) {
            this.applyCurrentCycle(currentCycle)
          }
        })
        .catch(error => {
          this.handleAlert({
            statusAlert: 'error',
            title: this.$t(
              'PerformanceTab.EntitiesConfigurations.alert.get.error'
            ),
            messageAlert: error?.response?.data?.message,
          })
        })
    },
  },

  watch: {
    _settings: {
      handler() {
        this.resetForm()
      },
      immediate: true,
      deep: true,
    },

    settingsForm: {
      handler() {
        this.resetForm()
      },
      deep: true,
    },
  },

  beforeMount() {
    this.loading = true
    this.setEntity()
    this.fetchDefaultSettings()
    this.getCycles({
      limit: this.cyclePagination.limit,
      offset: this.cyclePagination.offset,
    })
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
