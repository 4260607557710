<template>
  <div id="performance-page">
    <AlertBar ref="AlertBar"></AlertBar>

    <GoalsGroupSettings v-if="_canViewGroupSettings"></GoalsGroupSettings>

    <listCycleSettings
      v-if="_showListCycles"
      @display-alert="handleAlert($event)"
      @createNewCycle="createNewCycle"
      @editCycle="editCycle"
      @openCycleManagementForm="openCycleManagementForm"
    ></listCycleSettings>

    <v-card v-if="_showEntities" class="card" data-test-entity-management>
      <div class="header">
        <div class="header-title">
          <v-icon class="crown">fi-sr-crown</v-icon>
          <h1>{{ $t('PerformancePage.EntityConfig.title') }}</h1>

          <HowToBeginAndWatchAVideo
            video-type="listEntity"
            short
          ></HowToBeginAndWatchAVideo>
        </div>

        <div class="header-subtitle">
          <h3>{{ $t('PerformancePage.EntityConfig.subtitle') }}</h3>
        </div>
      </div>

      <v-divider></v-divider>

      <EntityOkrList ref="entityList" :entity-list="entityList"></EntityOkrList>
    </v-card>

    <v-card class="card" data-test-tag-unity-management>
      <div class="header">
        <div class="header-title">
          <v-icon class="crown">fi-sr-crown</v-icon>
          <h1>{{ $t('PerformancePage.ConfigTagAndUnity.title') }}</h1>
          <HowToBeginAndWatchAVideo
            video-type="listEntity"
            short
          ></HowToBeginAndWatchAVideo>
        </div>
        <div class="header-subtitle">
          <h3>
            {{ $t('PerformancePage.ConfigTagAndUnity.subtitle') }}
          </h3>
        </div>
      </div>

      <v-divider></v-divider>

      <v-row>
        <v-col cols="12">
          <TagManagement @alert="handleAlert($event)"></TagManagement>
        </v-col>

        <v-col cols="12"> </v-col>
      </v-row>
    </v-card>

    <SideQuickRegister
      v-if="quickRegister"
      ref="quickRegister"
      :title="quickRegister.actionSelected.title"
      :image-title="quickRegister.actionSelected.image"
      :header-buttons="quickRegister.headerButtons"
      :footer-buttons="quickRegister.footerButtons"
      :popover-options="quickRegister.popoverOptions"
      :show="quickRegister.show"
      @handleAction="handleAction"
    >
      <router-view
        ref="SideQuickForm"
        :show-alert="handleAlert"
        @display-alert="handleAlert($event)"
        @close="closeSideQuick"
        @addCustomFields="
          openCustomFieldsForm('addCustomFields', { entityID: $event.entityID })
        "
        @editCustomFields="
          openCustomFieldsForm('editCustomFields', {
            id: $event.id,
            callback: $event.callback,
            entityID: $event.entityID,
          })
        "
        @editDefaultField="
          openDefaultFieldForm('editDefaultField', {
            id: $event.id,
            defaultField: $event,
          })
        "
        @handleNewPlannedForm="
          openNewPlannedForm('handleNewPlannedForm', {
            entityID: $event.entityID,
            newPlannedSeries: $event.newPlannedSeries,
            hierarchySetting: $event.hierarchySetting,
          })
        "
      ></router-view>
    </SideQuickRegister>
  </div>
</template>

<script>
import listCycleSettings from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/ListCycleSettings/ListCycleSettings.vue'
import EntityOkrList from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/EntityOkrList/EntityOkrList.vue'
import TagManagement from './Parts/TagManagement/TagManagement.vue'
import GoalsGroupSettings from '@/views/ManagementPage/Tabs/AccountPage/Tabs/PerformancePage/Parts/GoalsGroupSettings/GoalsGroupSettings.vue'

import { mapActions } from 'vuex'
import { fetchEntityList } from '@/providers/papers'
import {
  management_performance_entities,
  management_performance_group_roles,
  management_performance_cycle,
  management_performance_cycle_delete,
  management_performance_module,
} from '@/helpers/ability'

export default {
  name: 'PerformancePage',
  components: {
    EntityOkrList,
    TagManagement,
    listCycleSettings,
    GoalsGroupSettings,
  },
  inject: ['setSideQuickConfig'],
  props: {
    quickRegister: {
      type: Object,
    },
  },
  data() {
    return {
      entityList: [],
      from: 'PerformancePage',
      entityID: null,
    }
  },
  computed: {
    _showEntities() {
      return this.$can('access', management_performance_entities)
    },
    _showListCycles() {
      return this.$can('access', management_performance_cycle)
    },
    _showGroupRoles() {
      return this.$can('access', management_performance_group_roles)
    },
    _canCycleDelete() {
      return this.$can('access', management_performance_cycle_delete)
    },
    _canViewGroupSettings() {
      return this.$can('access', management_performance_module)
    },
  },
  methods: {
    ...mapActions(['setCurrentPage', 'setVisibleFilterLateral']),
    addNewPlanned(params) {
      const action = {
        confirmButton: this.$t('plannedForm.confirmButton'),
        title: this.$t('plannedForm.title'),
        action: 'addNewPlanned',
      }

      const footer = [
        {
          name: this.$t('plannedForm.btnCancel'),
          action: 'close',
          type: 'text',
        },
        {
          name: this.$t('plannedForm.btnSave'),
          action: 'submit',
          type: 'default',
        },
      ]

      this.setSideQuickConfig(true, action, footer)

      const newParams = {
        id: 'new',
        ...params,
      }
      return newParams
    },
    addCustomFields(params) {
      const action = {
        confirmButton: this.$t('newCustomFields.confirmButton'),
        title: this.$t('newCustomFields.title'),
        action: 'addCustomFields',
      }

      const footer = [
        {
          name: this.$t('newCustomFields.cancelButton'),
          action: 'close',
          type: 'text',
        },
        {
          name: this.$t('newCustomFields.confirmButton'),
          action: 'submit',
          type: 'default',
        },
      ]

      this.setSideQuickConfig(true, action, footer)

      const newParams = {
        id: 'new',
        ...params,
      }

      return newParams
    },
    editDefaultFields(params) {
      const action = {
        title: this.$t('viewDefaultFields.title'),
        action: 'editDefaultFields',
      }

      this.setSideQuickConfig(true, action)

      return params
    },
    editCustomFields(params) {
      const action = {
        title: this.$t('viewCustomFields.title'),
        action: 'editCustomFields',
      }

      this.setSideQuickConfig(true, action)

      return params
    },
    openNewPlannedForm(
      act,
      params = { id: 'new' },
      from = 'PerformancePage',
      query = {}
    ) {
      if (act === 'handleNewPlannedForm') params = this.addNewPlanned(params)

      params.entityType = this.$route.params.entityType
      params.entityClass = this.$route.params.entityClass

      const route = {
        name: 'plannedForm',
        params,
        query,
      }

      this.from = from
      this.$router.push(route)
    },
    openCustomFieldsForm(
      act,
      params = { id: 'new' },
      from = 'PerformancePage',
      query = {}
    ) {
      if (act === 'addCustomFields') params = this.addCustomFields(params)
      if (act === 'editCustomFields') params = this.editCustomFields(params)

      params.entityType = this.$route.params.entityType
      params.entityClass = this.$route.params.entityClass

      const route = {
        name: 'CustomFieldForm',
        params,
        query,
      }

      this.from = from
      this.$router.push(route)
    },
    openDefaultFieldForm(
      act,
      params = { id: 'new' },
      from = 'PerformancePage',
      query = {}
    ) {
      if (act === 'editDefaultField') params = this.editDefaultFields(params)

      params.entityType = this.$route.params.entityType
      params.entityClass = this.$route.params.entityClass

      const route = {
        name: 'DefaultFieldForm',
        params,
        query,
      }

      this.from = from
      this.$router.push(route)
    },
    handleAlert(event) {
      const config = {
        ...(event.title && { title: event.title }),
        messagePrefix: '',
        description: event.messageAlert,
        type: event.statusAlert ? event.statusAlert : event.type,
        hasLeftBorder: false,
        hasFooter: false,
      }

      this.$refs.AlertBar.displayAlert(config)
    },
    handleActions() {
      let actions = {
        close: this.closeSideQuick,
        submit: this.submitSideQuick,
        delete: this.handleDeleteCycle,
      }
      return actions
    },
    handleAction(act) {
      const { name } = this.$route
      let actions

      switch (name) {
        case 'PaperGroupForm':
        case 'EntityOkrForm':
        case 'NewCycle':
        case 'EditCycle':
        case 'CustomFieldForm':
        case 'DefaultFieldForm':
        case 'plannedForm':
        case 'MetadataGroupForm':
        case 'ManagementCycle':
          actions = this.handleActions()
          break
      }

      if (actions) actions[act]()
    },
    closeSideQuick() {
      const parent = this.$route.matched[this.$route.matched.length - 2]
      const { name } = parent

      switch (name) {
        case 'EntityOkrForm':
          if (this.$route.name !== 'EntityOkrForm') {
            this.setSideQuickConfig(false)

            this.$nextTick(() => {
              const entityType = this.$route.params.entityType
              const entityClass = this.$route.params.entityClass

              this.openEntityOkrForm('editEntityOkr', {
                entityType,
                entityClass,
              })
            })
          }
          break
        case 'DefaultFieldForm':
          if (this.$route.name !== 'DefaultFieldForm') {
            this.setSideQuickConfig(false)
          }
          break
        case 'plannedForm':
          if (this.$route.name !== 'plannedForm') {
            this.setSideQuickConfig(false)
          }
          break
        case 'CustomFieldForm':
          if (this.$route.name !== 'CustomFieldForm') {
            this.setSideQuickConfig(false)
          }
          break
        case 'PerformancePage':
        default:
          this.setSideQuickConfig(false)

          if (this.$route.name !== 'PerformancePage')
            this.$router.push({ name: 'PerformancePage' })
          break
      }
    },
    submitSideQuick() {
      this.$refs.SideQuickForm.submit()
    },
    openEntityOkrForm(
      act,
      params = { id: 'new' },
      from = 'PerformancePage',
      query = {}
    ) {
      if (act === 'editEntityOkr') params = this.editEntityOkrForm(params)

      const route = {
        name: 'EntityOkrForm',
        params,
        query,
      }

      this.from = from
      this.$router.push(route).catch(() => {
        /* err */
      })
    },
    editEntityOkrForm(params) {
      const title = this.getEntityTitle(params.entityType, params.entityClass)
      const action = {
        confirmButton: this.$t('EntityOkrForm.submit'),
        title: title,
        action: 'EntityOkrForm',
        image: params.entityType,
      }
      const footer = []

      this.setSideQuickConfig(true, action, footer)

      return params
    },
    async fetchEntityList() {
      this.$refs.entityList.handleLoading()
      this.entityList = await fetchEntityList()
      this.$refs.entityList.handleLoading()
    },
    getEntityTitle(entityType, entityClass) {
      entityType = this.$t(`ViewObjective.${entityType}`)
      entityClass = entityClass
        ? this.$t(`ViewObjective.${entityClass}`)
        : false

      return entityClass
        ? `Configuração: ${entityType} ${entityClass}`
        : `Configuração: ${entityType}`
    },

    openFormOnUrlAccess() {
      const { name, params } = this.$route
      const { id, entityType, entityClass } = params

      switch (name) {
        case 'PaperGroupForm':
          if (id === 'new') return this.addPaperGroupForm({ id: 'new' })
          return this.editPaperGroupForm({ id: id })
        case 'EntityOkrForm':
          return this.editEntityOkrForm({ entityType, entityClass })
        case 'MetadataGroupForm':
          if (id === 'new') return this.addMetadataGroupForm({ id: 'new' })
          return this.editMetadataGroupForm({ id: id })
      }
    },

    openCycleManagementForm() {
      const action = {
        title: this.$t('managementCycleForm.title'),
        action: 'managementCycle',
      }

      const route = {
        name: 'ManagementCycle',
      }

      this.$router.push(route)

      this.setSideQuickConfig(true, action)
    },

    createNewCycle(node) {
      let params = null
      if (node) {
        params = { id: node.id }
      }

      const action = {
        title: this.$t('PerformanceTab.cycleForm.createTitle'),
        action: 'addCycle',
      }

      const footer = [
        {
          name: this.$t('PerformanceTab.cycleForm.btnCancelCycle'),
          action: 'close',
          type: 'text',
        },
        {
          name: this.$t('PerformanceTab.cycleForm.btnAddNewCycle'),
          action: 'submit',
          type: 'default',
        },
      ]

      const route = {
        name: 'NewCycle',
        params,
      }

      this.$router.push(route)

      this.setSideQuickConfig(true, action, footer)
    },
    editCycle(cycleSelected) {
      const params = {
        id: cycleSelected.id,
      }
      const action = {
        title: this.$t('PerformanceTab.cycleForm.editTitle'),
        action: 'editCycle',
      }

      const footer = []

      const popover = this._canCycleDelete
        ? [
            {
              action: 'delete',
              icon: 'fi fi-rr-trash',
              name: this.$t('Modal.buttonExcluir'),
            },
          ]
        : []

      const route = {
        name: 'EditCycle',
        params,
      }

      this.$router.push(route)

      this.setSideQuickConfig(true, action, footer, null, popover)
    },
    handleDeleteCycle() {
      this.$refs.SideQuickForm.deleteCycle()
    },
    openMetadataGroupForm(
      act,
      params = { id: 'new' },
      from = 'EntityOkrForm',
      query = {}
    ) {
      if (act === 'addMetadataGroup') {
        params = this.addMetadataGroupForm(params)
      }

      if (act === 'editMetadataGroup') {
        params = this.editMetadataGroupForm(params)
      }

      params.entityType = this.$route.params.entityType
      params.entityClass = this.$route.params.entityClass

      const route = {
        name: 'MetadataGroupForm',
        params,
        query,
      }

      this.from = from
      this.$router.push(route)
    },
    addMetadataGroupForm(params) {
      const action = {
        confirmButton: this.$t('addMetadataGroup.submit'),
        title: this.$t('addMetadataGroup.title'),
        action: 'addMetadataGroup',
      }

      const footer = [
        {
          name: this.$t('addMetadataGroup.cancel'),
          action: 'close',
          type: 'text',
        },
        {
          name: this.$t('addMetadataGroup.submit'),
          action: 'submit',
          type: 'default',
        },
      ]

      this.setSideQuickConfig(true, action, footer)

      return params
    },
    editMetadataGroupForm(params) {
      const action = {
        title: this.$t('editMetadataGroup.title'),
        action: 'editMetadataGroup',
      }
      const footer = []

      this.setSideQuickConfig(true, action, footer)

      return params
    },
  },
  mounted() {
    this.setCurrentPage('PerformancePage')
    this.setVisibleFilterLateral(false)

    const { params } = this.$route
    const { id, entityType } = params

    if (id || entityType) this.openFormOnUrlAccess()

    this.fetchEntityList()
  },
  destroyed() {
    this.setVisibleFilterLateral(true)
  },
  provide() {
    return {
      openEntityOkrForm: this.openEntityOkrForm,
      openCustomFieldsForm: this.openCustomFieldsForm,
      openMetadataGroupForm: this.openMetadataGroupForm,
    }
  },
}
</script>
<style lang="scss" scoped src="./style.scss" />
