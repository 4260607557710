<template>
  <div data-app class="overflow-hidden">
    <div class="card mt-0">
      <div class="header">
        <i class="fi-sr-arrow-left" @click="toggleViews(false)"></i>
        <div class="row">
          <div class="col-9">
            <div class="header-title">
              <h1>{{ $t('TreeView.title') }}</h1>
              <HowToBeginAndWatchAVideo videoType="managementPage" short />
            </div>
            <div class="header-subtitle">
              <h3>{{ $t('TreeView.subtitle') }}</h3>
            </div>
          </div>

          <div class="col-3">
            <i class="fi-rr-cloud-upload"></i>
            <div
              v-if="_canCreateGroup"
              class="add-new-btn add-new-btn-primary"
              @click="openAddNodeForm()"
            >
              <v-icon dark small class="mb-1">mdi-plus</v-icon>
              {{ $t('GroupsPage.addNew') }}
            </div>
          </div>
        </div>
      </div>

      <v-divider class="mb-1"></v-divider>
    </div>

    <div id="treeview-page" class="overflow-hidden">
      <TreeView
        ref="tree"
        :tree="tree"
        :hooks="hooks"
        :config="treeConfig"
        :loading="loading.tree"
        class="mt-1"
      >
        <template v-slot:search>
          <TreeSearch
            ref="treeSearch"
            v-bind="searchBarConfig"
            @reset="resetTree"
          />
        </template>
        <template v-slot:zoom="{ zoomScale }">
          <ZoomController ref="zoom" :zoomScale="zoomScale" :help="help" />
        </template>
        <template v-slot:nodes="{ node, collapsed }">
          <QuickNewNode
            v-if="node.quickNewNode"
            :node="node"
            :collapsed="collapsed"
            :config="treeConfig"
            :actions="actions"
            @handleAction="handleAction"
          />
          <TreeRoot
            v-else-if="node.root"
            ref="treeNode"
            :node="node"
            :config="treeConfig"
            :collapsed="collapsed"
            :actions="actions"
            :canCreateGroup="_canCreateGroup"
            @handleAction="handleAction"
          />
          <TreeNode
            v-else
            :node="node"
            :collapsed="collapsed"
            :config="treeConfig"
            :actions="actions"
            :canCreateGroup="_canCreateGroup"
            @handleAction="handleAction"
          />
        </template>
        <template v-slot:shortcuts="{ keys }">
          <Shortcuts :keys="keys" ref="modalShortcut" />
        </template>
      </TreeView>
    </div>

    <AlertBar ref="AlertBar">
      <div class="alert-container">
        <span @click="handleAlertAction('edit')">
          <i class="fi-rr-browser"></i>
          {{ $t('TreeView.alert.open') }}
        </span>
        <span @click="handleAlertAction('search')">
          <i class="fi-rr-search"></i>
          {{ $t('TreeView.alert.search') }}
        </span>
      </div>
    </AlertBar>
  </div>
</template>
<script>
import ZoomController from '@/components/TreeView/parts/ZoomController.vue'
import TreeSearch from '@/views/ManagementPage/Tabs/PeopleAndGroupPage/Tabs/GroupsPage/parts/TreeSearch/TreeSearch.vue'
import ItemOfList from '@/components/ItemOfList/ItemOfList.vue'
import Shortcuts from '@/components/TreeView/parts/Shortcuts.vue'
import QuickNewNode from '@/components/QuickNewNode/QuickNewNode'
import TreeView from '@/components/TreeView/TreeView'
import TreeNode from '@/components/TreeNode/TreeNode'
import TreeRoot from '@/components/TreeRoot/TreeRoot'

import {
  fetchGroupParents,
  fetchGroupsList,
  fetchAccountGroups,
  fetchGroupChildren,
} from '@/services/groups'

import {
  management_groups_create,
  management_groups_edit,
  management_groups_delete,
  management_groups_move,
  management_groups_duplicate,
} from '@/helpers/ability'

import { mapGetters } from 'vuex'

export default {
  name: 'TreeViewPage',
  components: {
    ZoomController,
    TreeSearch,
    Shortcuts,
    TreeRoot,
    TreeView,
    TreeNode,
    QuickNewNode,
  },
  inject: ['toggleViews', 'handleConfigModal'],
  data() {
    return {
      tree: [],
      loading: {
        tree: true,
      },
      stirredNode: {},
      searchBarConfig: null,
      actions: this.getAction(),
      hooks: {
        beforeNodeOpen: this.beforeNodeOpen,
        onEmptyClick: this.openAddNodeForm,
      },
      treeConfig: { nodeWidth: 300, nodeHeight: 144, levelHeight: 250 },
    }
  },
  computed: {
    ...mapGetters({
      account: 'currentAccount/account',
    }),
    _canCreateGroup() {
      return this.$can('access', management_groups_create)
    },
  },
  methods: {
    getAction() {
      const actions = []

      if (this.$can('access', management_groups_edit)) {
        actions.push({
          name: this.$t('TreeView.edit'),
          icon: 'fi-rr-browser',
          action: 'edit',
        })
      }

      if (this.$can('access', management_groups_move)) {
        actions.push({
          name: this.$t('TreeView.move'),
          icon: 'fi-rr-arrow-small-right',
          action: 'move',
        })
      }

      if (this.$can('access', management_groups_delete)) {
        actions.push({
          name: this.$t('TreeView.delete'),
          icon: 'fi-rr-trash',
          action: 'delete',
        })
      }

      if (this.$can('access', management_groups_duplicate)) {
        actions.push({
          name: this.$t('TreeView.duplicate'),
          icon: 'fi-rr-copy-alt',
          action: 'duplicate',
        })
      }

      return actions
    },
    handleAlertAction(act) {
      if (act === 'search') {
        this.$refs?.treeSearch?.insertText(this.stirredNode.name, true)
        return this.$refs.tree.overrideChildren(this.stirredNode)
      }

      if (act === 'edit') return this.openEditNodeForm(this.stirredNode)
    },
    handleAction(act, refNode = null) {
      if (!refNode) refNode = this.tree[0] //Root

      const actions = {
        add: this.createQuickNewNode,
        edit: this.openEditNodeForm,
        move: this.openMoveNodeModalForm,
        delete: this.openDeleteModalForm,
        duplicate: this.openDuplicateNodeModalForm,
        'expand-parent': this.expandParentFromNode,
      }

      return actions[act](refNode)
    },
    handleAlert(act, status, node) {
      const config = {
        description: node.title,
        type: status,
      }

      if (act === 'already-exists')
        config.messagePrefix = this.$t('TreeView.alert.already-exists')

      if (act === 'add') config.messagePrefix = this.$t('TreeView.alert.add')

      if (act === 'edit') config.messagePrefix = this.$t('TreeView.alert.edit')

      if (act === 'duplicate') {
        this.stirredNode = node
        config.messagePrefix = this.$t('TreeView.alert.duplicate')
        config.hasLeftBorder = true
        config.hasFooter = true
      }

      if (act === 'move-fail') {
        config.messagePrefix = this.$t('TreeView.alert.move-fail')
      }

      if (act === 'move') {
        this.stirredNode = node
        config.messagePrefix = this.$t('TreeView.alert.move')
        config.hasLeftBorder = true
        config.hasFooter = true
      }

      if (act === 'delete')
        config.messagePrefix = this.$t('TreeView.alert.delete')

      this.$refs.AlertBar.displayAlert(config)
    },
    createQuickNewNode(refNode) {
      const quickNewNode = {
        quickNewNode: true,
        parentGroupID: refNode.id,
        _parent: refNode,
      }

      const node = this.$refs.tree.insertCopy(quickNewNode, refNode, true)

      node.callback = newNode => {
        newNode.peopleCount = newNode.people.length
        newNode.childrenCount = 0
        refNode.childrenCount = refNode.childrenCount + 1

        this.$refs.tree.overrideNode(newNode, node)
      }
      node.fail = () => this.handleAlert('already-exists', 'alert', node)
      node.cancel = () => this.$refs.tree.removeNode(node)
    },
    openAddNodeForm() {
      const payload = {
        key: 'new',
        callback: null,
        action: 'addGroup',
      }

      this.$emit('handleAction', payload)
    },
    async handleCreated(newNode) {
      const tree = this.$refs?.tree
      const refNode = newNode.parentGroupID
        ? await tree.findNodeBy('id', newNode.parentGroupID)
        : this.tree[0]

      if (refNode) {
        refNode.childrenCount = (refNode.childrenCount || 0) + 1
        const target = this.$refs.tree.insertCopy(newNode, refNode)

        if (newNode.subgroupIDs.length) {
          this.moveSubgroups(newNode.subgroupIDs, target)
        }
      } else {
        if (newNode.subgroupIDs.length) {
          this.deleteSubgroups(newNode.subgroupIDs)
        }
      }

      this.handleAlert('add', 'success', refNode)
    },
    async handleUpdateSubgroups(act, groupID, subgroupID, showAlert) {
      const tree = this.$refs?.tree

      if (act === 'add') {
        const refNode = await tree.findNodeBy('id', groupID)

        if (refNode) return this.moveSubgroups([subgroupID], refNode)
        return this.deleteSubgroups([subgroupID])
      }

      if (act === 'remove') {
        const refNode = await tree.findNodeBy('id', subgroupID)

        if (refNode) return this.handleMoveTree(refNode, 'root', showAlert)
        return this.deleteSubgroups([subgroupID])
      }
    },
    moveSubgroups(subgroups, target) {
      const tree = this.$refs?.tree

      subgroups.forEach(async group => {
        const refNode = await tree.findNodeBy('id', group)

        if (target) target.childrenCount = target.childrenCount + 1

        if (refNode) {
          refNode._parent.childrenCount = refNode._parent.childrenCount - 1
          this.$refs.tree.moveNode(refNode, target, false)
        }
      })
    },
    deleteSubgroups(subgroups) {
      const tree = this.$refs?.tree

      subgroups.forEach(async group => {
        const refNode = await tree.findNodeBy('id', group)

        refNode._parent.childrenCount = refNode._parent.childrenCount - 1
        this.$refs.tree.removeNode(refNode)
      })
    },
    openEditNodeForm(refNode) {
      const act = 'edit'

      const callback = newNode => {
        this.$refs.tree.editNode(newNode, refNode)
        if (refNode.parentGroupID !== newNode.parentGroupID)
          this.handleMoveTree(refNode, refNode.parentGroupID)
        this.handleAlert(act, 'success', refNode)
      }

      const payload = {
        callback,
        key: refNode.id,
        action: 'viewGroup',
        target: structuredClone(refNode),
        act: 'edit',
      }

      this.$emit('handleAction', payload)
    },
    openDuplicateNodeModalForm(refNode) {
      const callback = async (duplicated, id) => {
        try {
          const treeRef = await this.$refs.tree.findNodeBy('id', id)

          if (treeRef) {
            duplicated.peopleCount = duplicated.people.length
            duplicated.childrenCount = 0
            treeRef.childrenCount = treeRef.childrenCount + 1

            this.$refs.tree.insertCopy(duplicated, treeRef)
          }
          this.handleAlert('duplicate', 'success', refNode)
        } catch {
          this.handleAlert('duplicate', 'success', refNode)
        }
      }

      this.handleConfigModal('duplicate', {
        callback: callback,
        target: structuredClone(refNode),
      })
    },
    async handleMoveTree(refNode, parentGroupID, showAlert = true) {
      if (!parentGroupID && showAlert)
        return this.handleAlert('move-fail', 'alert', refNode)

      try {
        const treeRef =
          parentGroupID === 'root'
            ? this.tree[0]
            : await this.$refs.tree.findNodeBy('id', parentGroupID)

        if (treeRef) {
          refNode.parentGroupID =
            parentGroupID === 'root' ? null : parentGroupID
          refNode._parent.childrenCount = refNode._parent.childrenCount - 1
          treeRef.childrenCount = treeRef.childrenCount + 1
          this.$refs.tree.moveNode(refNode, treeRef, false)
        }

        if (showAlert) this.handleAlert('move', 'success', refNode)
      } catch {
        this.$refs.tree.removeNode(refNode)
        if (showAlert) this.handleAlert('move', 'success', refNode)
      }
    },
    openMoveNodeModalForm(refNode) {
      const callback = id => this.handleMoveTree(refNode, id)

      this.handleConfigModal('move', {
        callback: callback,
        target: structuredClone(refNode),
      })
    },
    openDeleteModalForm(refNode) {
      const callback = () => {
        refNode.childrenCount = refNode.childrenCount - 1
        this.$refs.tree.removeNode(refNode)
        this.handleAlert('delete', 'success', refNode)
      }

      this.handleConfigModal('delete', {
        callback: callback,
        target: refNode,
      })
    },
    async handleUpdated(data) {
      const tree = this.$refs.tree

      if (tree) {
        const node = await this.$refs.tree.findNodeBy('id', data.id)
        const allowedFields = ['image', 'name', 'costCenter', 'inactive']

        if (node) allowedFields.forEach(key => (node[key] = data[key]))
      }
    },
    async handleUpdatePeople(groupID, newPeopleCount) {
      const treeRef = await this.$refs.tree.findNodeBy('id', groupID)

      if (treeRef) treeRef.peopleCount = newPeopleCount
    },
    async expandParentFromNode(refNode) {
      const childrenCount = refNode.childrenCount
      const parents = await fetchGroupParents(refNode)

      const lastNode = this.$refs.tree.overrideNode(parents, refNode)
      lastNode.childrenCount = childrenCount
    },
    async resetTree() {
      this.tree = []
      this.$refs.tree?.resetTree()
      this.loading.tree = true

      await this.fetchAccountGroups()

      this.$refs.tree?.resetTree()
      this.loading.tree = false
    },
    async beforeNodeOpen(node) {
      node._children = []
      const nodes = await fetchGroupChildren(node)
      nodes.forEach(newNode => this.$refs.tree.insertCopy(newNode, node))
    },
    help() {
      this.$refs.modalShortcut?.handleDialog()
    },
    initTreeSearch() {
      const callback = async hierarchy => {
        if (hierarchy.parentGroup?.id) hierarchy.hasHiddenParents = true
        this.$refs.tree.overrideChildren(hierarchy)
      }

      const submit = async selected => callback(selected)

      const search = async (text, limit, offset) =>
        fetchGroupsList(limit, text, offset)

      this.searchBarConfig = {
        component: ItemOfList,
        search: search,
        submit: submit,
        act: 'search',
      }
    },
    async fetchAccountGroups() {
      const children = await fetchAccountGroups()
      const tree = { ...this.account, children, childrenCount: children.length }

      this.tree = [tree]
    },
  },
  async mounted() {
    this.initTreeSearch()
    setTimeout(() => {
      this.resetTree()
    }, 200)
  },
  async updated() {
    const nodes = document.getElementsByClassName('node-slot')
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      if (node) {
        node.style.pointerEvents = 'none'
      }
    }
  },
}
</script>
<style lang="scss" scoped src="./style.scss" />
