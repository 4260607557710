<template>
  <div data-test-engagement-page class="engagement-page">
    <CardWrapper
      :title="$t('EngagementPage.title')"
      :subtitle="$t('EngagementPage.subtitle')"
      :helper-props="{
        videoType: 'EngagementPage',
        short: true,
      }"
    >
      <template #content>
        <ComposeForm
          ref="ComposeForm"
          class="form"
          :form-name="'notification'"
          :selectors="{
            fields: _fields,
          }"
          :lazy-submit="true"
          :lazy-mount="false"
          :storage-preferences="false"
          :display="{
            form: ['as-flex-grid'],
          }"
          @compose:values="setAccountSettings"
        >
          <template #[`field.loading`]>
            <v-skeleton-loader type="button"></v-skeleton-loader>
          </template>

          <template #climate="{ setValue, field, fieldsValues }">
            <ModuleSection
              :title="$t('EngagementPage.module.climate.title')"
              :module="'climate'"
              :value="fieldsValues[field.id] || false"
            >
              <CustomSwitch
                ref="default"
                :value="fieldsValues[field.id]"
                @input="setValue($event, field.id, field.type)"
              ></CustomSwitch>
            </ModuleSection>
          </template>

          <template #culture="{ setValue, field, fieldsValues }">
            <ModuleSection
              :upgrade="true"
              :title="$t('EngagementPage.module.culture.title')"
              :module="'culture'"
              :value="fieldsValues[field.id] || false"
            >
              <CustomSwitch
                ref="default"
                :value="fieldsValues[field.id]"
                @input="setValue($event, field.id, field.type)"
              ></CustomSwitch>
            </ModuleSection>
          </template>
        </ComposeForm>

        <ConfigureEmail v-if="false"></ConfigureEmail>

        <FormFooter
          v-if="_canManagementEngagementSurveyEdit & !form.loading"
          :saving="form.saving"
          @submit="handleSubmit"
          @cancel="handleDefaultValues"
        ></FormFooter>
      </template>
    </CardWrapper>
  </div>
</template>

<script>
import * as _permissions from '@/helpers/ability/permissions'
import { alertErrorBus, alertSuccessBus } from '@/helpers/alerts'

import {
  getManagementAccountSettings,
  putManagementAccountSettings,
} from '@/services/account'

import ModuleSection from '@/views/ManagementPage/Tabs/AccountPage/Tabs/EngagementPage/Partials/ModuleSection/ModuleSection.vue'
import ConfigureEmail from '@/views/ManagementPage/Tabs/AccountPage/Tabs/EngagementPage/Partials/ConfigureEmail/ConfigureEmail.vue'
import FormFooter from '@/views/ManagementPage/Tabs/AccountPage/Tabs/EngagementPage/Partials/FormFooter/FormFooter.vue'

export default {
  name: 'EngagementPage',

  components: {
    ModuleSection,
    ConfigureEmail,
    FormFooter,
  },

  data() {
    return {
      form: {
        loading: true,
        saving: false,
        settings: {},
      },
      lastFormSettings: null,
    }
  },

  computed: {
    _fields() {
      return [
        {
          loading: this.form.loading,

          id: 'surveyIntervalDays',
          _label: this.$t('EngagementPage.form.surveyIntervalDays.label'),
          type: 'ItemSelector',
          submit: {
            type: 'ArrayOf',
            identifier: 'id',
            mode: 'objectId',
          },

          ItemSelector: {
            readonly: !this._canManagementEngagementSurveyEdit,

            persistent: true,
            multiple: false,
            localSearch: true,
            inputConfig: {
              closeOnSelect: true,
              showAvatar: false,
              clearable: false,
            },
            menuConfig: {
              attach: false,
              showTabs: false,
            },
          },
          options: [
            {
              value: 'surveyIntervalDays',
              label: this.$t('EngagementPage.form.surveyIntervalDays.label'),
              type: 'listview',
              items: [
                {
                  id: 'weekly',
                  label: this.$t(
                    'EngagementPage.form.surveyIntervalDays.weekly'
                  ),
                },
                {
                  id: 'biweekly',
                  label: this.$t(
                    'EngagementPage.form.surveyIntervalDays.biweekly'
                  ),
                },
                {
                  id: 'monthly',
                  label: this.$t(
                    'EngagementPage.form.surveyIntervalDays.monthly'
                  ),
                },
              ],
            },
          ],
        },
        {
          loading: this.form.loading,

          id: 'deliverySurveyDate',
          _label: this.$t('EngagementPage.form.deliverySurveyDate'),
          type: 'CustomDatePicker',

          CustomDatePicker: {
            readonly: !this._canManagementEngagementSurveyEdit,

            inputConfig: {
              closeOnClick: true,
              rules: [],
            },
            menuConfig: {
              attach: false,
            },

            menuProps: {
              nudgeBottom: 12,
            },
          },
        },
      ]
    },

    _canManagementEngagementSurveyEdit() {
      return this.$can('access', _permissions.management_engagement_survey_edit)
    },
  },

  mounted() {
    this.$store.dispatch('setCurrentPage', 'EngagementPage')

    this.handleEngagementSettings()
  },

  methods: {
    handleDefaultValues(settings = this.lastFormSettings) {
      this.$set(this.form, 'settings', settings)
      this.handleApiItens(settings)
    },
    async handleSubmit() {
      if (this.form.loading) return
      this.form.saving = true

      await putManagementAccountSettings({
        ...this.form.settings,
        surveyIntervalDays: this.form.settings.surveyIntervalDays[0].id,
      })
        .then(() => {
          this.lastFormSettings = structuredClone(this.form.settings)
          alertSuccessBus(this.$t('EngagementPage.form.alerts.success'))
        })
        .catch(error => {
          alertErrorBus(
            this.$t('alerts.ManagementAccountSettings.error.put'),
            error
          )
        })
        .finally(() => {
          this.form.saving = false
        })
    },

    handleApiItens(settings = this.form.settings) {
      if (!this.$refs.ComposeForm) return

      this.$refs.ComposeForm.defineFieldsAsValue(settings, true)
      this.$refs.ComposeForm.setComposeFormPreferences()
    },

    async handleEngagementSettings() {
      this.form.loading = true

      return await getManagementAccountSettings()
        .then(({ data }) => {
          const settings = {
            ...data,
            surveyIntervalDays: [{ id: data.surveyIntervalDays }],
          }

          this.lastFormSettings = structuredClone(settings)
          this.handleDefaultValues(settings)

          this.form.loading = false
        })
        .catch(error => {
          alertErrorBus(
            this.$t('alerts.ManagementAccountSettings.error.get'),
            error
          )
        })
    },

    setAccountSettings(values) {
      this.$set(this.form, 'settings', values)
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
