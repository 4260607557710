<template>
  <section :data-test-integration-view="integrationID" class="integration-view">
    <div class="integration-view__content">
      <div class="integration-view__content--axel">
        <v-img
          contain
          max-height="6.25rem"
          :src="`${CDN_BASE_URL}/images/integration/axel-integration.png`"
        ></v-img>
      </div>

      <div class="integration-view__content--information">
        <div class="integration-view__content--title">
          <h2
            v-text="
              $t('IntegrationPage.integration.title', {
                integration: $t(`IntegrationPage.integration.${integrationID}`),
              })
            "
          ></h2>

          <HowToBeginAndWatchAVideo
            :videoType="`IntegrationPage-${integrationID}`"
            :short="true"
          ></HowToBeginAndWatchAVideo>
        </div>

        <span
          class="integration-view__content--subtitle"
          v-text="
            $t('IntegrationPage.integration.description', {
              integration: $t(`IntegrationPage.integrations.${integrationID}`),
            })
          "
        ></span>

        <ol
          v-if="workspaces.length"
          class="integration-view__content--workspaces"
        >
          <li
            v-for="workspace of workspaces"
            :key="workspace.id"
            :data-test-integration-view-workspace="workspace.id"
          >
            <div>
              <span
                data-test-integration-view-workspace-name
                v-text="workspace.name"
              ></span>

              <v-btn
                data-test-integration-view-workspace-remove
                v-if="_canManagementIntegrationsSlackDelete"
                icon
                class="integration-view__content--workspaces--remove"
                @click="removeIntegration(workspace.id)"
              >
                <v-icon size="1rem">fi-rr-trash</v-icon>
              </v-btn>
            </div>
          </li>
        </ol>
      </div>
    </div>

    <v-divider class="neutral-divider"></v-divider>

    <footer class="integration-view__footer">
      <v-spacer></v-spacer>

      <v-btn
        data-test-integration-view-connect
        v-if="_canManagementIntegrationsSlackEdit"
        outlined
        elevation="0"
        :href="addWorkspace(integrationID)"
      >
        <v-img
          contain
          height="1.5rem"
          width="1.5rem"
          :src="`${CDN_BASE_URL}/images/integration/${integrationID}.svg`"
        ></v-img>

        <span>
          <span v-text="$t('IntegrationPage.integration.enable')"></span>
          <span
            class="integration-view__footer--integration"
            v-text="$t(`IntegrationPage.integration.${integrationID}`)"
          ></span>
        </span>
      </v-btn>
    </footer>
  </section>
</template>

<script>
import * as _permissions from '@/helpers/ability/permissions'
import { connectIntegration, removeIntegration } from '@/services/integrations'
import { alertErrorBus } from '@/helpers/alerts'

export default {
  name: 'IntegrationView',
  props: {
    integrations: {
      type: Array,
      default: () => [],
    },

    integrationID: {
      type: String,
    },
  },

  data() {
    return {
      workspaces: [],
    }
  },

  computed: {
    _canManagementIntegrationsSlackDelete() {
      return this.$can(
        'access',
        _permissions.management_integrations_slack_delete
      )
    },
    _canManagementIntegrationsSlackEdit() {
      return this.$can(
        'access',
        _permissions.management_integrations_slack_edit
      )
    },
  },

  watch: {
    integrationID: {
      handler(value) {
        this.getIntegration(value)
      },
      immediate: true,
    },
  },

  methods: {
    async removeIntegration(code, integrationID = this.integrationID) {
      return await removeIntegration({ integrationID, code })
        .then(() => {
          this.removeIntegrationSuccess(code, integrationID)
        })
        .catch(error => {
          alertErrorBus(
            this.$t(
              'alerts.ManagementIntegrationsIntegrationIDCode.error.delete'
            ),
            error
          )
        })
    },
    async removeIntegrationSuccess(code, integrationID = this.integrationID) {
      const integration = this.integrations.find(
        item => item.id === integrationID
      )

      integration.workspaces = integration.workspaces.filter(
        item => item.id !== code
      )

      this.getIntegration(integrationID)
    },

    async handleFromSlack() {
      const { code, state } = this.$route.query
      if (state !== 'from-slack') return

      await this.connectIntegration(code, state)
    },
    async connectIntegration(code, state, integrationID = this.integrationID) {
      if (!code || !state) {
        return Promise.resolve()
      }

      return await connectIntegration({ integrationID, code })
        .then(() => {
          this.$router.replace({ query: {} })
        })
        .catch(error => {
          alertErrorBus(
            this.$t('alerts.ManagementIntegrationsIntegrationID.error.post'),
            error
          )
        })
    },

    getIntegration(integrationID = this.integrationID) {
      const integration = this.integrations.find(
        item => item.id === integrationID
      )

      this.$set(this, 'workspaces', integration.workspaces)
    },

    addWorkspace(integrationID = this.integrationID) {
      const integrationFn = {
        slack: this.addToSlackUrl,
      }[integrationID]

      if (!integrationFn) return

      return integrationFn()
    },

    addToSlackUrl() {
      const slackApiOauth = new URL(process.env.VUE_APP_SLACK_API_OAUTH)
      slackApiOauth.searchParams.set('scope', 'bot')
      slackApiOauth.searchParams.set(
        'client_id',
        process.env.VUE_APP_SLACK_CLIENTID
      )
      slackApiOauth.searchParams.set(
        'redirect_uri',
        window.location.href.split('?')[0]
      )
      slackApiOauth.searchParams.set('state', 'from-slack')

      return slackApiOauth.toString()
    },
  },

  beforeMount() {
    this.handleFromSlack()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
