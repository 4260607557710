var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"integration-view",attrs:{"data-test-integration-view":_vm.integrationID}},[_c('div',{staticClass:"integration-view__content"},[_c('div',{staticClass:"integration-view__content--axel"},[_c('v-img',{attrs:{"contain":"","max-height":"6.25rem","src":`${_vm.CDN_BASE_URL}/images/integration/axel-integration.png`}})],1),_c('div',{staticClass:"integration-view__content--information"},[_c('div',{staticClass:"integration-view__content--title"},[_c('h2',{domProps:{"textContent":_vm._s(
            _vm.$t('IntegrationPage.integration.title', {
              integration: _vm.$t(`IntegrationPage.integration.${_vm.integrationID}`),
            })
          )}}),_c('HowToBeginAndWatchAVideo',{attrs:{"videoType":`IntegrationPage-${_vm.integrationID}`,"short":true}})],1),_c('span',{staticClass:"integration-view__content--subtitle",domProps:{"textContent":_vm._s(
          _vm.$t('IntegrationPage.integration.description', {
            integration: _vm.$t(`IntegrationPage.integrations.${_vm.integrationID}`),
          })
        )}}),(_vm.workspaces.length)?_c('ol',{staticClass:"integration-view__content--workspaces"},_vm._l((_vm.workspaces),function(workspace){return _c('li',{key:workspace.id,attrs:{"data-test-integration-view-workspace":workspace.id}},[_c('div',[_c('span',{attrs:{"data-test-integration-view-workspace-name":""},domProps:{"textContent":_vm._s(workspace.name)}}),(_vm._canManagementIntegrationsSlackDelete)?_c('v-btn',{staticClass:"integration-view__content--workspaces--remove",attrs:{"data-test-integration-view-workspace-remove":"","icon":""},on:{"click":function($event){return _vm.removeIntegration(workspace.id)}}},[_c('v-icon',{attrs:{"size":"1rem"}},[_vm._v("fi-rr-trash")])],1):_vm._e()],1)])}),0):_vm._e()])]),_c('v-divider',{staticClass:"neutral-divider"}),_c('footer',{staticClass:"integration-view__footer"},[_c('v-spacer'),(_vm._canManagementIntegrationsSlackEdit)?_c('v-btn',{attrs:{"data-test-integration-view-connect":"","outlined":"","elevation":"0","href":_vm.addWorkspace(_vm.integrationID)}},[_c('v-img',{attrs:{"contain":"","height":"1.5rem","width":"1.5rem","src":`${_vm.CDN_BASE_URL}/images/integration/${_vm.integrationID}.svg`}}),_c('span',[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('IntegrationPage.integration.enable'))}}),_c('span',{staticClass:"integration-view__footer--integration",domProps:{"textContent":_vm._s(_vm.$t(`IntegrationPage.integration.${_vm.integrationID}`))}})])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }