import i18n from '@/plugins/i18n'
import {
  fetchPerformanceEntityList,
  fetchOkrGroupPapers,
  fetchEntityOkrSettings,
  updateEntityOkrSettings,
  deleteEntityRole,
} from '@/services/papers'

import context from '@/assets/icons/context.png'
import objective from '@/assets/icons/objective.png'
import keyresult from '@/assets/icons/key-result.png'
import initiative from '@/assets/icons/initiative.png'

export async function fetchEntityList() {
  const { data: entityList } = await fetchPerformanceEntityList()

  return entityList.map(entity => {
    const entityType = entity.entityType
    const entityClass = entity.entityClass

    const id = entity.id
    const key = entityClass ? `${entityType}-${entityClass}` : entityType
    const icon = {
      context: context,
      objective: objective,
      initiative: initiative,
      'key-result': keyresult,
    }[entity.entityType]

    const label = i18n.t(`PerformancePage.EntityConfig.list-labels.${key}`)

    return {
      id,
      key,
      icon,
      label,
      options: {
        [i18n.t('PerformancePage.EntityConfig.options.custom')]: null,
        [i18n.t('PerformancePage.EntityConfig.options.workstep')]: null,
        [i18n.t('PerformancePage.EntityConfig.options.workflow')]: null,
        [i18n.t('PerformancePage.EntityConfig.options.people-papers')]: true,
      },
      entityType,
      entityClass,
    }
  })
}

export const fetchOkrPapers = async (
  entityType,
  entityClass,
  canEdit,
  canDelete
) => {
  const { data: papers, headers } = await fetchOkrGroupPapers(
    entityType,
    entityClass
  )

  const xCount = headers['x-count']

  const parsedPapers = papers.map(paper => {
    const actions = []
    if (canEdit) actions.push('edit')
    if (canDelete && paper.deletable) actions.push('remove')

    return {
      id: paper.id,
      label: paper.label,
      functions: [`${paper.permissionsCount} Funcionalidades`],
      actions: actions,
    }
  })

  return { papers: parsedPapers, xCount }
}

export const fetchOkrSettings = async entityOkrID => {
  const { data: okrSettings } = await fetchEntityOkrSettings(entityOkrID)

  return okrSettings
}

export const formatPaper = paper => {
  return {
    id: paper.id,
    label: paper.label,
    functions: [`${paper.permissionsCount} Funcionalidades`],
    actions: ['edit', 'remove'],
  }
}

export const removePaper = async (removedPaper, papers) => {
  try {
    await deleteEntityRole(removedPaper.id)

    const index = papers.findIndex(paper => paper.id === removedPaper.id)
    return index
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
  }
}

export const updateOkrSettings = async (entityOkrID, payload) => {
  return await updateEntityOkrSettings(entityOkrID, payload)
}
