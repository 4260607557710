var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"engagement-page",attrs:{"data-test-engagement-page":""}},[_c('CardWrapper',{attrs:{"title":_vm.$t('EngagementPage.title'),"subtitle":_vm.$t('EngagementPage.subtitle'),"helper-props":{
      videoType: 'EngagementPage',
      short: true,
    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ComposeForm',{ref:"ComposeForm",staticClass:"form",attrs:{"form-name":'notification',"selectors":{
          fields: _vm._fields,
        },"lazy-submit":true,"lazy-mount":false,"storage-preferences":false,"display":{
          form: ['as-flex-grid'],
        }},on:{"compose:values":_vm.setAccountSettings},scopedSlots:_vm._u([{key:`field.loading`,fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"button"}})]},proxy:true},{key:"climate",fn:function({ setValue, field, fieldsValues }){return [_c('ModuleSection',{attrs:{"title":_vm.$t('EngagementPage.module.climate.title'),"module":'climate',"value":fieldsValues[field.id] || false}},[_c('CustomSwitch',{ref:"default",attrs:{"value":fieldsValues[field.id]},on:{"input":function($event){return setValue($event, field.id, field.type)}}})],1)]}},{key:"culture",fn:function({ setValue, field, fieldsValues }){return [_c('ModuleSection',{attrs:{"upgrade":true,"title":_vm.$t('EngagementPage.module.culture.title'),"module":'culture',"value":fieldsValues[field.id] || false}},[_c('CustomSwitch',{ref:"default",attrs:{"value":fieldsValues[field.id]},on:{"input":function($event){return setValue($event, field.id, field.type)}}})],1)]}}],null,true)}),(false)?_c('ConfigureEmail'):_vm._e(),(_vm._canManagementEngagementSurveyEdit & !_vm.form.loading)?_c('FormFooter',{attrs:{"saving":_vm.form.saving},on:{"submit":_vm.handleSubmit,"cancel":_vm.handleDefaultValues}}):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }