<template>
  <div class="planned-list" data-test-planned-list>
    <DetailSectionAccordion
      type="people"
      :title="$t('plannedList.title')"
      :showTooltip="true"
      :opened="true"
    >
      <DataTable
        data-test-planned-list-table
        :title="$t('plannedList.tableSearch')"
        :attach="true"
        :loading="loading"
        :hideHeader="true"
        :hideOption="true"
        :items="filteredItems"
        :headers="_tableHeaders"
        :enablePagination="true"
        :skeleton-lines="tableConfig.limit"
        :itemsPerPage="tableConfig.limit"
        :downLeftButton="_downLeftButton"
        @search="handleSearch($event)"
        @remove="handleDelete($event)"
      ></DataTable>

      <template v-slot:tooltip-description>
        <div class="custom-tooltip-message">
          {{
            $t('plannedList.tooltipMessage', {
              entityClass: $t(
                `plannedList.entityClass-name.${$route.params.entityClass}`
              ),
            })
          }}
        </div>
      </template>
    </DetailSectionAccordion>

    <DeleteNewPlannedModal
      ref="deleteNewPlannedModal"
      @handleDeletePlanned="handleDeletePlanned"
    ></DeleteNewPlannedModal>
  </div>
</template>
<script>
import {
  fetchEntityOkrSettings,
  updateEntityOkrSettings,
} from '@/services/papers/index.js'

import {
  management_performance_series_planned_create,
  management_performance_series_planned_delete,
} from '@/helpers/ability'

import { alertErrorMessage, alertSuccessMessage } from '@/helpers/alerts'

import DataTable from '@/components/DataTable/DataTable.vue'
import DeleteNewPlannedModal from './Partials/DeleteNewPlannedModal/DeleteNewPlannedModal.vue'

export default {
  name: 'PlannedList',
  data() {
    return {
      loading: false,
      tableConfig: {
        limit: 4,
      },
      filteredItems: [],
      items: [],
      hierarchySettings: null,
    }
  },
  components: {
    DataTable,
    DeleteNewPlannedModal,
  },
  props: {
    settingsID: {
      type: String,
      required: true,
    },
  },
  computed: {
    _canAccessSeriesPlannedCreate() {
      return this.$can('access', management_performance_series_planned_create)
    },
    _canAccessSeriesPlannedDelete() {
      return this.$can('access', management_performance_series_planned_delete)
    },
    _downLeftButton() {
      if (this._canAccessSeriesPlannedCreate) {
        return {
          click: this.handleNewPlannedForm,
          text: this.$t('plannedList.btnNewPlanned'),
          icon: 'mdi-plus',
        }
      }

      return null
    },
    _tableHeaders() {
      return [
        {
          text: this.$t('plannedList.header.name'),
          value: 'cycle',
          type: 'text-status',
        },
        {
          text: this.$t('plannedList.header.actions'),
          value: 'actions',
          type: 'action-buttons',
          width: '100',
        },
      ]
    },
  },
  methods: {
    handleNewPlannedForm() {
      this.$emit('handleNewPlannedForm')
    },
    handleDeletePlanned(event) {
      if (this.hierarchySettings) {
        delete this.hierarchySettings.id
      }

      const deleteIndex = this.hierarchySettings.newPlannedSeries.findIndex(
        el => el.cycle.id === event.id
      )

      if (deleteIndex === -1) return

      this.hierarchySettings.newPlannedSeries.splice(deleteIndex, 1)

      this.update()
    },
    async update() {
      const newPayload = {
        ...this.hierarchySettings,
        ...(!!this.hierarchySettings?.suggestedCycle?.id && {
          suggestedCycle: { id: this.hierarchySettings.suggestedCycle.id },
        }),
      }

      await updateEntityOkrSettings(this.settingsID, newPayload)
        .then(() => {
          const alertPayload = alertSuccessMessage(
            this.$t('plannedList.successMessage')
          )
          this.displayAlert(alertPayload)
          this.fetchPlannedList()
          this.$emit('handleHierarchySettings', this.hierarchySettings)
        })
        .catch(error => {
          const alertPayload = alertErrorMessage(
            this.$t('plannedList.errorMessage'),
            error
          )
          this.displayAlert(alertPayload)
        })
    },
    handleDelete(event) {
      this.$refs.deleteNewPlannedModal.handleOpen(event)
    },
    handleSearch(event) {
      const array = []
      if (event.name != '') {
        this.items.map(el => {
          if (el?.cycle?.label.toLowerCase().includes(event.name.toLowerCase()))
            array.push(el)
        })

        this.filteredItems = array
        return
      }

      if (event.name === '') this.fetchPlannedList()
    },
    async fetchPlannedList() {
      try {
        this.loading = true
        this.items = []

        const { data: res } = await fetchEntityOkrSettings(this.settingsID)

        this.hierarchySettings = res

        if (res?.newPlannedSeries && res?.newPlannedSeries.length > 0) {
          res.newPlannedSeries.map(e => {
            this.items.push({
              actions: this._canAccessSeriesPlannedDelete ? ['remove'] : [],
              id: e.cycle.id,
              cycle: {
                label: e.cycle.title,
                status: '',
              },
              copyPreviousPlannedValues: e.copyPreviousPlannedValues,
            })
          })
        }

        this.filteredItems = structuredClone(this.items)

        this.loading = false
      } catch (error) {
        const alertPayload = alertErrorMessage(
          this.$t('PerformanceTab.EntitiesConfigurations.alert.get.error'),
          error
        )
        this.displayAlert(alertPayload)
      }
    },
    displayAlert(alertPayload) {
      this.$emit('display-alert', alertPayload)
    },
  },
  mounted() {
    this.fetchPlannedList()
  },
}
</script>
<style lang="scss" scoped src="./style.scss" />
