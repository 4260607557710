<template>
  <div id="GoalsGroupSettings" data-test-list-goals-settings>
    <CardWrapper
      class="card"
      :title="$t('PerformancePage.PerformanceModule.title')"
      :subtitle="$t('PerformancePage.PerformanceModule.subtitle')"
      :helper-props="{ videoType: 'listEntity', short: true }"
    >
      <template #content>
        <v-card-text class="card-text-content">
          <v-card-subtitle class="card-text-content-context">
            {{ $t('PerformancePage.PerformanceModule.goals.context') }}
          </v-card-subtitle>
          <div class="switch-container">
            <CustomSwitch
              v-model="goalsContextActive"
              :label="$t('PerformancePage.PerformanceModule.goals.active')"
              :custom-props="{ inset: true }"
              color="primary"
              :readonly="!_canEditGoals"
              :loading="false"
            ></CustomSwitch>
            <span class="switch-container-description">
              ({{ $t('PerformancePage.PerformanceModule.goals.description') }})
            </span>
          </div>
        </v-card-text>

        <v-divider class="divider"></v-divider>

        <v-card-text class="card-text-content">
          <v-card-subtitle class="card-text-content-context">
            {{ $t('PerformancePage.PerformanceModule.kpis.context') }}
          </v-card-subtitle>
          <div class="switch-container">
            <CustomSwitch
              v-model="kpisContextActive"
              :label="$t('PerformancePage.PerformanceModule.kpis.active')"
              :custom-props="{ inset: true }"
              color="primary"
              :readonly="!_canEditKpis"
              :loading="false"
            ></CustomSwitch>
            <span class="switch-container-description">
              ({{ $t('PerformancePage.PerformanceModule.kpis.description') }})
            </span>
          </div>
        </v-card-text>

        <v-divider
          v-if="_canEditGoals || _canEditKpis"
          class="divider"
        ></v-divider>

        <v-card-actions
          v-if="_canEditGoals || _canEditKpis"
          class="card-actions-content"
        >
          <v-spacer></v-spacer>
          <v-btn
            class="v-btn--text v-btn--text-transform v-btn--text-transform-text"
            text
            @click="cancel"
          >
            {{ $t('PerformancePage.PerformanceModule.cancel') }}
          </v-btn>
          <v-btn
            class="v-btn--text-transform"
            color="primary"
            elevation="0"
            :loading="saving"
            @click="save"
          >
            {{ $t('PerformancePage.PerformanceModule.save') }}
          </v-btn>
        </v-card-actions>
      </template>
    </CardWrapper>
  </div>
</template>

<script>
import {
  getManagementAccountPerformanceSettings,
  putManagementAccountPerformanceSettings,
} from '@/services/account'
import { alertErrorBus, alertSuccessBus } from '@/helpers/alerts'
import {
  management_performance_goals_edit,
  management_performance_kpi_edit,
} from '@/helpers/ability'

export default {
  name: 'GoalsGroupSettings',
  data() {
    return {
      goalsContextActive: false,
      originalGoalsContextActive: false,
      kpisContextActive: false,
      originalKpisContextActive: false,
      saving: false,
    }
  },
  computed: {
    _canEditGoals() {
      return this.$can('access', management_performance_goals_edit)
    },
    _canEditKpis() {
      return this.$can('access', management_performance_kpi_edit)
    },
  },
  created() {
    this.fetchSettings()
  },
  methods: {
    cancel() {
      this.goalsContextActive = structuredClone(this.originalGoalsContextActive)
      this.kpisContextActive = structuredClone(this.originalKpisContextActive)
    },
    async save() {
      this.saving = true
      try {
        const payload = {
          goalModuleEnabled: this.goalsContextActive,
          kpiModuleEnabled: this.kpisContextActive,
        }
        await putManagementAccountPerformanceSettings(payload)
        alertSuccessBus(
          this.$t('alerts.ManagementAccountSettingsPerformance.success')
        )
        this.originalGoalsContextActive = this.goalsContextActive
      } catch (error) {
        alertErrorBus(
          this.$t('alerts.ManagementAccountSettingsPerformance.error.put'),
          error
        )
      } finally {
        this.saving = false
      }
    },
    async fetchSettings() {
      try {
        const { data: response } =
          await getManagementAccountPerformanceSettings()
        this.goalsContextActive = response?.goalModuleEnabled
        this.originalGoalsContextActive = response?.goalModuleEnabled

        this.kpisContextActive = response?.kpiModuleEnabled
        this.originalKpisContextActive = response?.kpiModuleEnabled
      } catch (error) {
        alertErrorBus(
          this.$t('alerts.ManagementAccountSettingsPerformance.error.get'),
          error
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped src="./style.scss" />
