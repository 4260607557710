var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CardWrapper',{attrs:{"title":_vm.$t('IntegrationPage.title'),"subtitle":_vm.$t('IntegrationPage.subtitle'),"helperProps":{
    videoType: 'IntegrationsPage',
    short: true,
  },"data-test-integrations-list":""},scopedSlots:_vm._u([{key:"content",fn:function(){return _vm._l((_vm.integrations),function(integration){return _c('section',{key:integration.id,staticClass:"integration-item",class:{
        [integration.id]: true,
        'integration-has-workspaces': integration.workspaces.length,
      },attrs:{"data-test-integration-item":integration.id}},[_c('div',{staticClass:"integration-image"},[_c('v-img',{attrs:{"contain":"","max-width":"2.5rem","max-height":"2.25rem","src":`${_vm.CDN_BASE_URL}/images/integration/${integration.id}.svg`}}),_c('h2',{domProps:{"textContent":_vm._s(_vm.$t(`IntegrationPage.integration.${integration.id}`))}})],1),_c('div',{staticClass:"integration-description"},[_c('p',{staticClass:"integration-description--title",domProps:{"textContent":_vm._s(
            _vm.$t('IntegrationPage.integrations.title', {
              integration: _vm.$t(
                `IntegrationPage.integrations.${integration.id}`
              ),
            })
          )}}),_c('span',{staticClass:"integration-description--subtitle"},[_c('span',{staticClass:"integration-description--reminder",domProps:{"textContent":_vm._s(_vm.$t('IntegrationPage.integrations.reminder'))}}),_c('span',{domProps:{"textContent":_vm._s(
              _vm.$t('IntegrationPage.integrations.description', {
                integration: _vm.$t(
                  `IntegrationPage.integrations.${integration.id}`
                ),
              })
            )}})])]),_c('div',{staticClass:"integration-actions"},[(integration.workspaces.length)?_c('div',{staticClass:"integration-actions__status"},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('IntegrationPage.integrations.enabled'))}}),_c('v-icon',{attrs:{"size":"1rem"}},[_vm._v("fi-sr-checkbox")])],1):_vm._e(),_c('v-btn',{staticClass:"integration-actions__view-details",attrs:{"data-test-integration-item-view-details":"","color":"primary","elevation":"0","text":!!integration.workspaces.length},on:{"click":function($event){return _vm.handleIntegration(integration)}}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('IntegrationPage.integrations.open'))}}),_c('v-icon',{attrs:{"size":"1rem"}},[_vm._v("fi-sr-arrow-small-right")])],1)],1)])})},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }