import { render, staticRenderFns } from "./TableView.vue?vue&type=template&id=518ff326&scoped=true&"
import script from "./TableView.vue?vue&type=script&lang=js&"
export * from "./TableView.vue?vue&type=script&lang=js&"
import style0 from "./style.scss?vue&type=style&index=0&id=518ff326&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "518ff326",
  null
  
)

export default component.exports