<template>
  <div data-test-configure-email class="configure-email">
    <header>
      <div class="header-title">
        <v-icon v-if="upgrade" class="crown" size="0.875rem">
          fi-sr-crown
        </v-icon>
        <h2 v-text="$t('EngagementPage.module.email.title')"></h2>

        <HowToBeginAndWatchAVideo
          data-test-card-wrapper-begin-and-watch
          :videoType="'EngagementPage-email'"
          :short="true"
        ></HowToBeginAndWatchAVideo>

        <div class="bordered-status">
          <span v-text="$t('EngagementPage.module.email.soon')"></span>
        </div>
      </div>

      <div class="header-subtitle">
        <span v-text="$t('EngagementPage.module.email.subtitle')"></span>
      </div>
    </header>

    <v-divider class="neutral-divider"></v-divider>

    <div class="content">
      <span v-text="$t('EngagementPage.module.email.soon-description')"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfigureEmail',
  props: {
    upgrade: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
